import React, {useState, useEffect} from 'react';
import { Popup, Dropdown } from '@partssourceinc/react-ui-core';
import axios from "axios";
import {defaultHomeScreens} from 'data/DefaultHomeScreen';

const MyAccountChangePreferencesDialog = ({onCancel, onConfirm, user, addresses, shippingPriorities, onFacilityChange}) => {
    const conditions = {
        'Command Center': user.settings.hasDashboardv2Access,
        'Awaiting PO': user.settings.showAwaitingPoMenu,
        'Approvals': user.settings.approver,
    }
    const filteredPages = defaultHomeScreens.filter(s => [undefined, true].includes(conditions[s.text]));     
    const [isLoading, setIsLoading] = useState(false);
    const [tempHomeScreen, setTempHomeScreen] = useState(user.settings.defaultHomeScreen);
    const [tempShippingPriorityId, setTempShippingPriorityId] = useState(user.settings.defaultShippingPriorityId);
    const [tempFacilityId, setTempFacilityId] = useState(user.settings.defaultFacilityId);
    const [tempShippingAddressId, setTempShippingAddressId] = useState(user.settings.defaultShippingAddressId);
    const [tempShippingAddresses, setTempShippingAddresses] = useState(addresses);    
    useEffect(() => {
    }, []);

    function reload() {     
        onConfirm();
    }
    
    const handleChangeDefaultPage = (val) => {
        if (tempHomeScreen !== val)
            setTempHomeScreen(val);
    };
    const handleChangeDefaultFacility = (val) => {
        const newVal = val;
        if (tempFacilityId !== newVal) {
            setTempFacilityId(newVal);
            if (newVal !== '' && newVal !== '(select with order)') {
                onFacilityChange(newVal)
                axios.post(`/ShoppingService/api/v1/company/addresses/${newVal}`).then(x => {
                    setTempShippingAddresses(x.data.shippingAddresses);
                });
            }
        }
    };
    const handleShippingAddressChange = (val) => {
        if (tempShippingAddressId !== val)
            setTempShippingAddressId(val);
    };
    const handleShippingPriorityId = (val) => {
        if (tempShippingPriorityId !== val)
            setTempShippingPriorityId(val);
    };
    const onSave = () => {
        let data = {defaultHomeScreen: (tempHomeScreen === '') ? '-1' : tempHomeScreen,
            defaultFacilityId: tempFacilityId,
            defaultShippingAddressId: tempShippingAddressId,
            defaultShippingPriorityId: tempShippingPriorityId};
        axios.post('/SettingsService/api/v1/save/usersettings', data).then(() => {
            reload();
        });
    };

    return (
        <React.Fragment>
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSave}
                loading={isLoading}
            >
                <h1 className="title">Default Preferences</h1>
                <Dropdown label="Default Landing Page" onChange={(e) => handleChangeDefaultPage(e.target.value)}
                    options={filteredPages} selectedValue={tempHomeScreen} suppressBlankOption={false} valueField="value" textField="text" />
                <Dropdown label="Default Facility" onChange={(e) => handleChangeDefaultFacility(e.target.value)}
                    options={user.facilities} selectedValue={tempFacilityId} suppressBlankOption={false} valueField="facilityId" textField="facilityName" />
                <Dropdown id="shipAddress" name="shipAddress" label="Default Shipping Address" valueField="addressId" textField="description" showErrorMessage={false}
                    options={tempShippingAddresses} selectedValue={tempShippingAddressId} onChange={(e) => handleShippingAddressChange(e.target.value)} />
                <Dropdown id="shipPriority" name="shipPriority" label="Default Shipping Priority" valueField="id" textField="description" showErrorMessage={false}
                    options={shippingPriorities} selectedValue={tempShippingPriorityId} onChange={(e) => handleShippingPriorityId(e.target.value)} />
            </Popup>
            
        </React.Fragment>

    );

}

export default MyAccountChangePreferencesDialog;
