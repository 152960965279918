import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Popup, TextField, Checkbox } from '@partssourceinc/react-ui-core';
import axios from "axios";
import {actionCreators as NetworkActions} from 'stores/Network';
import 'less/login.less';

const MyAccountChangePasswordDialog = ({onCancel, onConfirm, user}) => {
    const [currentPassword, setCurrentPassword] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
   
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const onConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
        if (value == newPassword)
            setIsConfirmPasswordValid(true)
        else
            setIsConfirmPasswordValid(false)
    }

    const onNewPasswordChange = (value) => {
        setNewPassword(value);
        if (value == confirmPassword)
            setIsConfirmPasswordValid(true)
        else
            setIsConfirmPasswordValid(false)
    }

    const onSubmitPassword = async () => {

        if (!currentPassword || !newPassword || !isConfirmPasswordValid) {
            setShowError(true);
            return;
        }

        let request = {
            ContactId: user.info.contactId,
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
        };

        setIsLoading(true);
        const response = await axios.post(`ShoppingService/api/v1/customerAdmin/changePassword`, request);
        setIsLoading(false);
        if (response.data.result == 0) { // success
            onConfirm();
        } else if (response.data.result == 2) { // bad current password
            if (response.data.message)
                setErrorMsg(response.data.message);
            else 
                setErrorMsg('Your current password is not correct, please change current password.') 
        } else if (response.data.result == 3) { // error
            if (response.data.message)
                setErrorMsg(response.data.message);
            else 
                setErrorMsg('Some error ocured please try again.') 
        }
    };

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    }

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <React.Fragment>
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSubmitPassword}
                loading={isLoading}>
                <h1 className="title">Change Password</h1>

                {
                    errorMsg && <div style={{textAlign: 'center', color: 'red'}}> {errorMsg} </div>
                }

                <div style={{position: 'relative'}}>
                    <TextField 
                        type={showCurrentPassword ? 'text' : 'password'} 
                        id="tempPassword" 
                        tabIndex="1" 
                        text={currentPassword} 
                        value={currentPassword} 
                        onChange={(e) => setCurrentPassword(e.target.value) } 
                        showErrorMessage={showError && !currentPassword}
                        placeholder="Current Password" 
                        label="Current Password" 
                        className="security-input" />
                    <i className={'fa ' + (showCurrentPassword ? 'fa-eye' : 'fa-eye-slash') + ' security-input-eye'} style={{position: 'absolute', right: '15px', top: '18px', cursor: 'pointer'}} aria-hidden="true" onClick={toggleCurrentPasswordVisibility} />
                </div>

                <div>
                    <div className="change-pass-label" style={{marginTop: '10px'}}>New password must be between 5 and 17 characters long.</div>
                </div>

                <div style={{position: 'relative'}}>
                    <TextField 
                        type={showNewPassword ? 'text' : 'password'} 
                        id="newPassword" 
                        tabIndex="2" 
                        value={newPassword} 
                        onChange={(e) => onNewPasswordChange(e.target.value) }  
                        showErrorMessage={showError && !newPassword}
                        placeholder="New Password" 
                        label="New Password" 
                        className="security-input" />
                    <i className={'fa ' + (showNewPassword ? 'fa-eye' : 'fa-eye-slash') + ' security-input-eye'} style={{position: 'absolute', right: '15px', top: '18px', cursor: 'pointer'}} aria-hidden="true" onClick={toggleNewPasswordVisibility} />
                </div>

                <div style={{position: 'relative'}}>
                    <TextField 
                        type={showConfirmPassword ? 'text' : 'password'} 
                        id="confirmPassword" 
                        tabIndex="3" 
                        value={confirmPassword} 
                        onChange={(e) => onConfirmPasswordChange(e.target.value) }  
                        showErrorMessage={showError && (!confirmPassword || !isConfirmPasswordValid)}
                        placeholder="Confirm Password" 
                        label="Confirm Password" 
                        className="security-input" />
                    <i className={'fa ' + (showConfirmPassword ? 'fa-eye' : 'fa-eye-slash') + ' security-input-eye'} style={{position: 'absolute', right: '15px', top: '18px', cursor: 'pointer'}} aria-hidden="true" onClick={toggleConfirmPasswordVisibility} />
                </div>  
            </Popup>            
        </React.Fragment>
    );
}

export default MyAccountChangePasswordDialog;
