export const defaultHomeScreens = [
    {
        value: 8,
        text: 'Catalog',
    },
    {
        value: 6,
        text: 'Approvals',
    },
    {
        value: 9,
        text: 'Awaiting PO',
    },
    {
        value: 1,
        text: 'eQuotes',
    },
    {
        value: 7,
        text: 'Command Center',
    },
];
