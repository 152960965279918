import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Popup, TextField, Checkbox } from '@partssourceinc/react-ui-core';
import axios from "axios";
import {actionCreators as NetworkActions} from 'stores/Network';
import 'less/login.less';
import {Loader} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
`;

const MFAOptInText = styled.div`
    @media(max-width: 375px) {
        margin-top: 45px;
    }

`

const MyAccountChangeMFADialog = ({onCancel, onConfirm, user}) => {

    const dispatch = useDispatch();
    const mfaOptIn = useSelector(state => state.network.tokenInfo.mfaOptIn);
    const [mfaOptInEnabled, setMfaOptInEnabled] = useState(mfaOptIn);
    const oktaAuthentication = useSelector(state => state.system.siteSettings.oktaAuthentication);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoadingSetting, setIsLoadingSetting] = useState(false);

    useEffect(() => {
        if (oktaAuthentication)
            loadMfaOptin();
    }, [oktaAuthentication]);

    const onSubmitMFA = async () => {
        if (mfaOptInEnabled !== mfaOptIn) {
            const msg = getMfaConfirmationMsg(); // get before saving to capture state
            await saveMfaOptin();
            onConfirm(msg);
            return;
        } else {
            return;
        }
    };

    const loadMfaOptin = async () => {
        const {setMfaOptIn} = NetworkActions;
        setIsLoadingSetting(true);

        try{
            const response  = await axios.get(`ShoppingService/api/v1/customerAdmin/${user.info.contactId}/property?propertyName=MFA_OptIn`);
            if (response.data.result !== 1) {
                // if downstream calls failed for what ever 
                setErrorMsg(response.data.messages.join(','));
            } else {
                setMfaOptInEnabled(!!response.data.value);
                await dispatch(setMfaOptIn(!!response.data.value))
            }
        }catch {
            setErrorMsg('An error ocurred while reading properties');
        }

        setIsLoadingSetting(false);
    }

    const saveMfaOptin = async () => {
        const {setMfaOptIn} = NetworkActions;

        if (mfaOptInEnabled !== mfaOptIn) {
            let request = {
                ContactId: user.info.contactId,
                Value: mfaOptInEnabled,
            };

            setIsLoading(true);
            try {
                await axios.put('ShoppingService/api/v1/customerAdmin/setMFAOption', request);
                await dispatch(setMfaOptIn(mfaOptInEnabled));
            }
            catch { 
                setErrorMsg('Some error ocurred while trying to update MFA setting. Please try again.') 
            }
            setIsLoading(false);
        } 
        return;
    }

    const getMfaConfirmationMsg = () => {
        
        if (mfaOptInEnabled !== mfaOptIn) {
            let msg = '';
            if (mfaOptIn) // it was on - it is turned off
                msg = 'Multi-Factor authentication is now disabled';
            else 
                msg = 'You will be prompted to configure Multi-Factor authentication on your next login';
            return msg;
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSubmitMFA}
                disableConfirm={isLoadingSetting}
                loading={isLoading}>
                <h1 className="title">Multi-Factor Authentication (MFA)</h1>

                {
                    errorMsg && <div style={{textAlign: 'center', color: 'red'}}> {errorMsg} </div>
                }

                <div style={{position: 'relative'}}>
                
                {isLoadingSetting && <LoaderContainer>
                    <Loader />
                </LoaderContainer>}
                    <MFAOptInText>Checking the box below will enable your account to be secured by an additional factor. You&apos;ll be prompted for enrollment during your next login.</MFAOptInText>
                    <Checkbox
                        checked={mfaOptInEnabled} 
                        onChange={(elmt) => setMfaOptInEnabled(elmt.checked)}
                        label={'Turn on MFA for My Account'}
                    />
                </div>
            </Popup>            
        </React.Fragment>
    );
}

export default MyAccountChangeMFADialog;
