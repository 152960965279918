import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { Popup, TextField } from '@partssourceinc/react-ui-core';
import {stripAwayPhoneNumberSymbols, getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import axios from 'axios';

const MyAccountChangeUsernameDialog = ({onCancel, onConfirm, disableUsername, contact}) => {
    const [username, setUsername] = useState(contact.info.email);
    const [phone, setPhone] = useState(getFormattedPhoneNumber(contact.info.phoneNumber));
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [showErrors, setShowErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const {user: {settings}} = useSelector((state) => ({
        user: state.user,
    }));

    const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ?
        getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

    const validatePhoneNumber = (number) => {
        if (!number)
            return false;

        let ptrn = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
        if (number.match(ptrn)) {
            let fnumber = ('' + number).replace(/\D/g, '');
            if (fnumber.length > 11 || fnumber.length < 10)
                return false;

            return true;
        } else
            return false;
    } 

    const onSave = () => {
        let tempIsPhoneValid = validatePhoneNumber(phone);

        if (!username || !tempIsPhoneValid) {
            setIsPhoneValid(tempIsPhoneValid);
            setShowErrors(true);
            return;
        }

        let request = {
            Id: contact.info.contactId,
            Email: username,
            OrigEmail: contact.info.email,
            FirstName: contact.info.firstName,
            LastName: contact.info.lastName,
            PhoneNumber: stripAwayPhoneNumberSymbols(phone),
            OrigPhoneNumber: contact.info.phoneNumber,
            EditEmail: !disableUsername,
        }        

        setIsLoading(true);
        axios.put(`ShoppingService/api/v1/customerAdmin/contactInformation`, request).then((response) => {
            setIsLoading(false);
            if (response.data == 1) { // success
                onConfirm(username, phone);
            } else if (response.data == 2) { // error
                setErrorMsg('Some error ocured please try again.')
            } else if (response.data == 3) { // bad email
                setErrorMsg('Your user email have bad email format, please change username.') 
            } else if (response.data == 4) { // duplicate email
                setErrorMsg('Your username exist in system, please change username.')
            }
        });
    }

    return (
        <Popup
            confirmText="Save"
            cancelText="Cancel"
            show={true}
            hideButtons={false}
            onCancel={onCancel}
            onConfirm={onSave}
            loading={isLoading}
        >
            <h1 className="title">User Information</h1>
            {
                errorMsg && <div style={{textAlign: 'center', color: 'red'}}> {errorMsg} </div>
            }

            <TextField
                type="email"
                label="Username/Work Email"
                placeholder="Username/Work Email"
                onChange={(e) => {
                    setUsername(e.target.value); setErrorMsg(null) 
                }}
                className="list-name"
                text={username}
                showErrorMessage={showErrors && !username}
                tabIndex={0}
                disabled={disableUsername} />
            {
                disableUsername && <i style={{cursor: 'pointer', float: 'right', marginTop: '-30px', marginRight: '10px'}} onMouseOver={() => {
                    setShowTooltip(true)
                }} 
                onMouseOut={() => {
                    setShowTooltip(false)
                }} className="fa fa-question-circle product_tooltip"> {showTooltip && <span>We are unable to reset your username due to your integration - Should you need assistance, please contact our Sales Support Team at <a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>}</i>
            }

            <TextField
                type="text"
                label="Phone Number"
                placeholder="Phone Number"
                onChange={(e) => {
                    setPhone(getFormattedPhoneNumber(e.target.value)); setErrorMsg(null)
                }}
                className="list-name"
                text={phone}
                showErrorMessage={showErrors && !isPhoneValid}
                tabIndex={1}
                maxLength={12} />
        </Popup>
    );

}
export default MyAccountChangeUsernameDialog;
