import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import BreadCrumbs from 'components/BreadCrumbs';
import PageMetaData from 'components/PageMetaData';
import {Loader} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import axios from 'axios';
import {getFormattedPhoneNumber, logEvent} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {getSettings} from '../store/User';
import {defaultHomeScreens} from 'data/DefaultHomeScreen';
import MyAccountChangeUsernameDialog from '../components/MyAccountChangeUsernameDialog';
import MyAccountChangePreferencesDialog from '../components/MyAccountChangePreferencesDialog';
import MyAccountChangePasswordDialog from '../components/MyAccountChangePasswordDialog';
import MyAccountChangeMFADialog from '../components/MyAccountChangeMFADialog';
import CustomerAdminInformDialog from './CustomerAdmin/CustomerAdminInformDialog';
import './CustomerAdmin/customer-admin-user-details.less';

const LoaderContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function MyAccountPersonalSetting() {
    const dispatch = useDispatch();
    const [user, setUser] = useState(useSelector((state) => state.user));
    const userId = user.info.contactId;
    const history = useHistory();
    const isPersonal = (user.info.companyTypeId === 21); 
    const [loadingAction, setLoadingAction] = useState(false);
    const [showAllLocations, setShowAllLocations] = useState(false);
    const [showUserInfromationDialog, setShowUserInformationDialog] = useState(false);
    const [showUserPreferencesDialog, setShowUserPreferencesDialog] = useState(false);
    const [showUserPasswordDialog, setShowUserPasswordDialog] = useState(false);
    const [showMFADialog, setShowMFADialog] = useState(false);
    const [showUserInformDialog, setShowUserInformDialog] = useState(false);
    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [shippingPriorities, setShippingPriorities] = useState([]);
    const [confirmMsg, setConfirmMsg] = useState('');
    const [confirmTitle, setConfirmTitle] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [showMFATooltip, setMFAShowTooltip] = useState(false);
    const oktaAuthentication = useSelector(state => state.system.siteSettings.oktaAuthentication);
    const dedicatedPhoneNumber = user.settings.dedicatedPhoneNumber ?
        getFormattedPhoneNumber(user.settings.dedicatedPhoneNumber) : defaultPhoneNumber;

    useEffect(() => {
        loadShippingPriorities();
        loadShippingAddresses();
    }, []);
    const reload = async() => {
        let action = await dispatch(getSettings());
        if (action.response.data.settings) {
            let tempUser = {... user};
            tempUser.settings = action.response.data.settings;
            setUser(tempUser);
        }
    }     
    const conditions = {
        'Command Center': user.settings.hasDashboardv2Access,
        'Awaiting PO': user.settings.showAwaitingPoMenu,
        'Approvals': user.settings.approver,
    }
    const filteredPages = defaultHomeScreens.filter(s => [undefined, true].includes(conditions[s.text])); 
    const defaultPageName = () => {
        let defaultPage = filteredPages.find(p => p.value == user.settings.defaultHomeScreen); 
        return (defaultPage) ? defaultPage.text : 'N/A';
    }
    const defaultFacilityName = () => {
        let defaultFacility = user.facilities.find(f => f.facilityId == user.settings.defaultFacilityId); 
        return (defaultFacility) ? defaultFacility.facilityName : 'N/A';
    }
    const loadShippingAddresses = (facilityId = null) => {
        let defaultFacilityId = facilityId || user.settings.defaultFacilityId;
        if (defaultFacilityId && defaultFacilityId !== '-1' && defaultFacilityId !== '(select with order)') {
            axios.post(`/ShoppingService/api/v1/company/addresses/${defaultFacilityId}`).then(x => {
                setShippingAddresses(x.data.shippingAddresses)
            });  
        }
    }

    const loadShippingPriorities = () => {
        axios.get(`/SettingsService/api/v1/defaultShippingPriorities`).then(x => {
            setShippingPriorities(x.data)
        });  
    }

    const defaultAddressName = () => {
        let defaultAddress = shippingAddresses.find(a => a.addressId == user.settings.defaultShippingAddressId);
        return (defaultAddress) ? defaultAddress.description : 'N/A';
    }
    const defaultShipPriority = () => {
        let shipPriority = shippingPriorities.find(s => s.id == user.settings.defaultShippingPriorityId);
        return (shipPriority) ? shipPriority.description : 'N/A';
    }
    
    const onUserPreferencesConfirm = () => {
        logEvent('Personal Settings Edit', {UserId: userId});
        reload(); 
        setShowUserPreferencesDialog(false);
    }

    const onUserPreferencesCancel = () => {
        setShowUserPreferencesDialog(false);
    }

    const onUserInformationConfirm = (email, phoneNumber) => {
        let tempUser = {... user};
        tempUser.info.email = email;
        tempUser.info.phoneNumber = phoneNumber;
        setUser(tempUser)
        logEvent('Personal Settings Edit', {UserId: userId});
        setShowUserInformationDialog(false);
    }

    const onUserInformationCancel = () => {
        setShowUserInformationDialog(false);
    }

    const onChangePasswordConfirm = () => {
        logEvent('Personal Settings Edit', {UserId: userId});  
        setShowUserPasswordDialog(false);
        setConfirmTitle('Notification');
        setConfirmMsg('You successfully changed your password');
        setShowUserInformDialog(true);
    }

    const onChangePasswordCancel = () => {
        setShowUserPasswordDialog(false);
    }

    const onMFAConfirm = (msg) => {
        logEvent('Personal Settings Edit', {UserId: userId});  
        setShowMFADialog(false);
        setConfirmTitle('Notification');
        setConfirmMsg(msg || 'You successfully changed your MFA settings');
        setShowUserInformDialog(true);
    }

    const onMFACancel = () => {
        setShowMFADialog(false);
    }

    const onChangePasswordClick = () => {
        if (user.settings.isIntegratedUser)
            return;
      
        setShowUserPasswordDialog(true);       
    }

    const onMFAClick = () => {
        if (user.settings.isIntegratedUser)
            return;
      
        setShowMFADialog(true);       
    }

    const onUserInformConfirm = () => {
        setShowUserInformDialog(false);
    }

    const handleFacilityChange = (facilityId) => {
        loadShippingAddresses(facilityId)
    }
  
    return (
        <React.Fragment>
            {user ? (
                <div className="full-row on-site-main-content" style={{background: 'linear-gradient(0, #f1f1f0 40%, white 45%)'}}>
                    {loadingAction && <LoaderContainer>
                        <Loader />
                    </LoaderContainer>}
                    <div className="row on-site-default-page">
                        <div className="col-xs-12">
                            <header className="on-site-header">
                                <section>
                                    <PageMetaData
                                        title="My Account"
                                        pageType="other"
                                        trackAnalytics={true}
                                    />
                                    <BreadCrumbs style={{marginTop: '0px'}} />
                                </section>

                                <section className="top-row">
                                    <div className="back-text" onClick= {() => history.goBack()}>
                                        <i
                                            className="fa fa-chevron-left"
                                            aria-hidden="true"
                                            style={{paddingRight: 10}}
                                        />
                        Back to Settings
                                    </div>
                                </section>
                                <section className="title-row">
                                    <div className="title-row-top">
                                        <span className={`title`}>Personal</span>    
                                    </div>
                                </section>
                                <section className="title-row">
                                    <div className="title-row-top">                            
                                        <span className="action-links" onClick={() => {
                                            onChangePasswordClick() 
                                        }}>
                                            CHANGE PASSWORD 
                                            {
                                                user.settings.isIntegratedUser && <i style={{cursor: 'pointer', marginTop: '-30px', marginRight: '10px'}} onMouseOver={() => {
                                                    setShowTooltip(true)
                                                }} 
                                                onMouseOut={() => {
                                                    setShowTooltip(false)
                                                }} className="fa fa-question-circle product_tooltip"> {showTooltip && <span>We are unable to reset your password due to your integration - Should you need assistance, please contact our Sales Support Team at <a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>}</i>
                                            }  
                                        </span> 
                          
                                    </div>
                                </section>
                                {oktaAuthentication && <section className="title-row">
                                    <div className="title-row-top">                            
                                        <span className="action-links" onClick={() => {
                                            onMFAClick() 
                                        }}>
                                            MULTI-FACTOR AUTHENTICATION 
                                            {
                                                user.settings.isIntegratedUser && <i style={{cursor: 'pointer', marginTop: '-30px', marginRight: '10px'}} onMouseOver={() => {
                                                    setMFAShowTooltip(true)
                                                }} 
                                                onMouseOut={() => {
                                                    setMFAShowTooltip(false)
                                                }} className="fa fa-question-circle product_tooltip"> {showMFATooltip && <span>We are unable to update your seting due to your integration - Should you need assistance, please contact our Sales Support Team at <a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>}</i>
                                            }  
                                        </span> 
                          
                                    </div>
                                </section>}
                            </header>
                        </div>
                        <div className="col-xs-12">
                            <div className="service-detail" style={{padding: 0}}>
                                <aside className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="col-xs-12 left-col">
                                        <div style={{padding: '10px 0'}}>
                                            <div className="user-avatar">
                                                <div style={{margin: '20px', width: '98px', height: '98px', borderRadius: '49px', backgroundColor: '#005ba6', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <span style={{fontSize: '30px', color: 'white'}}>{user.info.firstName.charAt(0)}</span>
                                                    <span style={{fontSize: '30px', color: 'white'}}>{user.info.lastName.charAt(0)}</span>
                                                </div>
                                                <span className="full-name">{user.info.fullName} </span>
                                                <span className="text" style={{color: '#949494'}}>Organization</span>
                                                <span className="text" style={{marginBottom: '10px'}}>{user.facility.facilityName}</span>                           
                                            </div>
                                            <a href={`mailto:${user.info.email}`} style={{color: '#005da6', fontSize: '18px', fontWeight: '600', marginBottom: '10px', textDecoration: 'none'}}>{user.info.email}</a>
                                            <div style={{marginTop: '10px'}}>Last sign in: <span style={{fontWeight: '600'}}>{moment(user.info.lastLoginDate).format('MM/DD/YYYY')}</span></div>
                                            <div>Created: <span style={{fontWeight: '600'}}>{moment(user.info.createdDate).format('MM/DD/YYYY')}</span></div>
                                        </div>
                                    </div>
                                </aside>
                                <main className="col-md-8 col-sm-12 col-xs-12">
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div style={{minHeight: '20px', padding: '10px'}}>
                                                <span style={{fontWeight: '600'}}>CONTACT INFORMATION</span>
                                                <span style={{float: 'right', color: '#949494', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                                    setShowUserInformationDialog(true) 
                                                }} >Edit</span>
                                            </div>
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Username/Work Email: </span><span style={{fontWeight: '600'}}>{user.info.email}</span>  
                                            </div>  
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Phone Number: </span><span style={{fontWeight: '600'}}>{getFormattedPhoneNumber(user.info.phoneNumber)}</span>  
                                            </div>  
                                        </div>
                                    </div>
                                    {!isPersonal && <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div style={{minHeight: '20px', padding: '10px'}}>
                                                <span style={{fontWeight: '600'}}>GROUP</span>                                 
                                            </div> 
                                            <div style={{padding: '5px 10px'}}>
                                                <span style={{fontWeight: '600'}}>{user.info.groupName ? user.info.groupName : 'N/A' }</span>  
                                            </div>    
                                        </div>
                                    </div>}
                                    {!isPersonal && <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div style={{minHeight: '20px', padding: '10px'}}>
                                                <span style={{fontWeight: '600'}}>LOCATIONS</span>                                
                                            </div> 
                                            { user.facilities.length < 3 || showAllLocations ?
                                                user.facilities.map((x) => {
                                                    return (<div style={{padding: '5px 10px'}}>
                                                        <span style={{fontWeight: '600'}}>{x.facilityName}</span>  
                                                    </div>)
                                                })
                                                : user.facilities.slice(0, 3).map((x) => {
                                                    return (<div style={{padding: '5px 10px'}}>
                                                        <span style={{fontWeight: '600'}}>{x.facilityName}</span>  
                                                    </div>)
                                                })
                                            }
                                            {
                                                user.facilities.length > 3 && !showAllLocations ? <span onClick={() => {
                                                    setShowAllLocations(true); 
                                                }} style={{margin: '10px', color: '#949494', textDecoration: 'underline', cursor: 'pointer'}}> Show more ({user.facilities.length - 3}) </span> : null
                                            }
 
                                        </div>
                                    </div>}
                                    {!isPersonal && <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div style={{minHeight: '20px', padding: '10px'}}>
                                                <span style={{fontWeight: '600'}}>DEFAULT PREFERENCES</span>
                                                <span style={{float: 'right', color: '#949494', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                                    setShowUserPreferencesDialog(true) 
                                                }} >Edit</span>                                 
                                            </div> 
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Default Landing Page: </span> <span style={{fontWeight: '600'}}>{defaultPageName() }</span>  
                                            </div>
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Default Location: </span> <span style={{fontWeight: '600'}}>{defaultFacilityName() }</span>  
                                            </div>
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Default Shipping Address: </span> <span style={{fontWeight: '600'}}>{defaultAddressName() }</span>  
                                            </div>
                                            <div style={{padding: '5px 10px'}}>
                                                <span>Default Shipping Priority: </span> <span style={{fontWeight: '600'}}>{defaultShipPriority() }</span>  
                                            </div>     
                                        </div>
                                    </div>}
                                </main>    
                            </div>
                        </div>
                    </div>
                </div>     
        
            ) : (
                null
            )}
            { showUserInfromationDialog && <MyAccountChangeUsernameDialog onConfirm={onUserInformationConfirm} onCancel={onUserInformationCancel} contact={user} disableUsername={user.settings.isIntegratedUser} /> } 
            { showUserPreferencesDialog && <MyAccountChangePreferencesDialog onConfirm={onUserPreferencesConfirm} onCancel={onUserPreferencesCancel} user={user} addresses={shippingAddresses} shippingPriorities={shippingPriorities} onFacilityChange={handleFacilityChange} /> } 
            { showUserPasswordDialog && <MyAccountChangePasswordDialog onConfirm={onChangePasswordConfirm} onCancel={onChangePasswordCancel} user={user} /> } 
            { showMFADialog && <MyAccountChangeMFADialog onConfirm={onMFAConfirm} onCancel={onMFACancel} user={user} /> } 
            { showUserInformDialog && <CustomerAdminInformDialog onConfirm={onUserInformConfirm} title={confirmTitle} msg={confirmMsg} /> } 
        </React.Fragment>
    );
}
 
